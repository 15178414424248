import React, { useMemo } from "react"

const FigureSection = ({ numbers }) => {
  const figures = useMemo(
    () => [
      { name: "New Customers", number: numbers.newCustomers },
      { name: "Social Media Followers", number: numbers.socialMediaFollowers },
      { name: "Market Capital", number: numbers.marketCapital },
      { name: "More Conversions", number: numbers.moreConversions },
    ],
    [numbers]
  )

  return (
    <section className="border-b">
      <div className="container mx-auto max-w-6xl px-6 py-28 grid grid-cols-1 lg:grid-cols-2 gap-10">
        <div className="lg:w-10/12">
          <h1 className="font-medium text-3xl leading-relaxed tracking-tight">
            How we improved this brand.
            <br />
            See the numbers.
          </h1>
          <p className="mt-6 leading-relaxed">
            We enjoyed working on this project sit aspernatur aut odit aut
            fugit, sed do less consequunt magni dolores.
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 sm:gap-14">
          {figures.map(({ name, number }, index) => (
            <div key={`post-detail-figure-${index}`} className="space-y-2">
              <h2 className="text-2xl">
                {number} <span className="text-sm">%</span>
              </h2>
              <h3 className="text-sm text-gray-400">{name}</h3>
              <p className="leading-relaxed">
                Nemo enim ipsam volupt quiames voluptas sit asper natur aut odit
                aut fugit sed.
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default FigureSection
