import React from "react"
import { navigate } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { ChevronDoubleRightIcon } from "@heroicons/react/solid"

const roles = [
  "Design Brainstorming",
  "iOS & Android Development",
  "Selling & Marketing Strategy",
]

const HeroSection = ({ name, heroImage }) => (
  <>
    <section className="min-h-[60vh] flex items-center">
      <div className="container mx-auto max-w-6xl px-6 py-10 grid grid-cols-1 lg:grid-cols-3 gap-y-12 lg:gap-x-20 lg:gap-y-0">
        <div className="lg:col-span-2">
          <h1 className="font-medium text-5xl leading-normal tracking-tighter">
            {name}
          </h1>
          <p className="mt-5 leading-loose">
            Working with the team at sed do eiusmod tempor incididunt labore et
            dolores magna aliqua. {name} was created to unde omnis iste natus
            error amis voluptatem est accusantium doloremque lauda totam remus
            quia vibem est.
          </p>
        </div>
        <div className="space-y-4 lg:self-end">
          <h3 className="text-sm text-gray-400">Role</h3>
          <div className="space-y-2">
            {roles.map((role, index) => (
              <div key={`project-detail-role-${index}`}>{role}</div>
            ))}
          </div>
        </div>
        <div>
          <button
            className="lg:mt-8 py-4 px-6 border-2 flex justify-center items-center space-x-2 hover:bg-blue-50 transition duration-200 ease-in-out"
            onClick={() => navigate("/contact")}
          >
            <span>Start a project</span>
            <ChevronDoubleRightIcon className="w-4 h-4 text-gray-400" />
          </button>
        </div>
      </div>
    </section>
    <section>
      <GatsbyImage image={getImage(heroImage)} alt={name} />
    </section>
  </>
)

export default HeroSection
