import React from "react"

const AboutSection = ({ name }) => {
  return (
    <section className="border-b">
      <div className="container mx-auto max-w-6xl px-6 py-28 grid grid-cols-1 lg:grid-cols-12 gap-8">
        <div className="lg:col-span-4">
          <h1 className="font-medium text-3xl leading-relaxed tracking-tight">
            A brand talking for itself. The best experience.
          </h1>
        </div>
        <div className="space-y-6 lg:col-span-7 lg:col-start-6">
          <p className="leading-relaxed text-justify">
            {name} was created to unde omnis iste natus error sit voluptatem est
            accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
            quae ab illo inventore veritatis et quasi architecto beatae vitae
            dicta sunt.
          </p>
          <p className="leading-relaxed text-justify">
            Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut
            fugit, sed quia consequuntur magni dolores eos qui ratione
            voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem
            ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non
            numquam eius modi tempora incidunt ut labore et dolore magnam.
          </p>
        </div>
      </div>
    </section>
  )
}

export default AboutSection
