import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const RelatedProjectSection = ({ relatedProjects }) => {
  return (
    <section className="bg-blue-50">
      <div className="container mx-auto max-w-6xl px-6 py-28">
        <h1 className="font-medium text-3xl leading-relaxed tracking-tight">
          More related work.
        </h1>
        <div className="mt-14 grid grid-cols-1 sm:grid-cols-2 gap-12">
          {relatedProjects.map(({ id, name, slug, image }) => (
            <div key={id} className="group">
              <Link to={`/projects/${slug}`}>
                <div className="overflow-hidden">
                  <GatsbyImage
                    image={getImage(image)}
                    className="transition duration-500 ease-in-out transform group-hover:scale-125"
                    alt={name}
                  />
                </div>
                <h3 className="mt-4 group-hover:text-gray-400 transition duration-500 ease-in-out">
                  {name}
                </h3>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default RelatedProjectSection
