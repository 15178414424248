import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const GallerySection = ({ gallery }) => {
  return (
    <section className="border-b">
      <div className="container mx-auto max-w-6xl px-6 py-28 space-y-20">
        {gallery.map((image, index) => (
          <GatsbyImage
            key={`post-detail-gallery-${index}`}
            image={getImage(image)}
            alt="gallery"
          />
        ))}
      </div>
    </section>
  )
}

export default GallerySection
