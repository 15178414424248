import React from "react"
import { graphql } from "gatsby"

import Seo from "../components/Seo"
import Layout from "../components/Layout"
import HeroSection from "../components/ProjectDetailPage/HeroSection"
import AboutSection from "../components/ProjectDetailPage/AboutSection"
import GallerySection from "../components/ProjectDetailPage/GallerySection"
import FigureSection from "../components/ProjectDetailPage/FigureSection"
import RelatedProjectSection from "../components/ProjectDetailPage/RelatedProjectSection"

const ProjectDetailPage = ({ data }) => {
  const { name, heroImage, gallery, numbers, relatedProjects } =
    data?.contentfulProject

  return (
    <Layout>
      <Seo title={name} />
      <HeroSection name={name} heroImage={heroImage} />
      <AboutSection name={name} />
      <GallerySection gallery={gallery} />
      <FigureSection numbers={numbers} />
      <RelatedProjectSection relatedProjects={relatedProjects} />
    </Layout>
  )
}

export default ProjectDetailPage

export const query = graphql`
  query ($id: String!) {
    contentfulProject(id: { eq: $id }) {
      name
      heroImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      gallery {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      numbers {
        newCustomers
        marketCapital
        moreConversions
        socialMediaFollowers
      }
      relatedProjects {
        id
        name
        slug
        image {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  }
`
